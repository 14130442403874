<template>
    <div>
        <fromSearch :object_='object_' @searchFun='getList' />
        <avue-crud ref="crud" :option="tableOption1" :data="page.list" :page="page" :table-loading="listLoading"
            @size-change="sizeChange"
            @current-change="currentChange" >
            <template #menu-left>
                <!-- <el-button   @click="openView(2)">提现 </el-button> -->
                <el-button type="primary"   @click="openView(3)">{{$t('button.add')}}  </el-button>
            </template>
            <template #status="scope">
                <el-switch v-model="scope.row.status" :inactive-value='0' :active-value='1' @change='changeStatus(scope.row)'/>
            </template> 
            <template #caozuo="scope">
                <el-button text type="primary"
                    size="small"    @click="openView(4,scope.row)">{{$t('button.update')}} </el-button>
                <!-- <el-button text type="primary"
                    size="small"    @click="openView(5,scope.row)">查看NFT流水 </el-button> -->
            </template> 
            <template #createTime="scope">
                {{turnTimeFun(scope.row.createTime)}}
            </template>
            <template #updateTime="scope">
                {{turnTimeFun(scope.row.updateTime)}}
            </template>
        </avue-crud>  
        <el-dialog v-model="innerVisible1" class="initer_"
            width="600" :title="textArray[pageStyle]" >
            <addView v-if='pageStyle === 3' @successFun='successFun' ref='addView1'/>
            <updateView v-else-if='pageStyle === 4' @successFun='successFun' ref='updateView1'/>
            <!-- <addView v-else-if='pageStyle === 1'/>
            <addView v-else-if='pageStyle === 2'/>
            <addView v-else-if='pageStyle === 5'/> -->

            <template #footer>
                <el-button    @click="innerVisible1 = false">{{$t('button.closeQ')}} </el-button>
                <el-button type="primary"   @click="completeFun">{{$t('button.tijiao')}}</el-button>
            </template>
        </el-dialog>

    </div>
</template>
<script setup>
    import Api_ from '@/api/index'
    import { ref,nextTick,getCurrentInstance } from 'vue'
    import fromSearch from '@/components/fromSearch.vue'
    import { object } from '@/const/from/nft/hotWallet'
    import { tableOption } from '@/const/crud/nft/chainCharge'
    import mixins from '@/views/mixins/page'
    import { ElNotification } from 'element-plus'
    import addView from './add/add.vue'
    import updateView from './update/index.vue'
    import { turnTimeFun } from '@/utils/util.js'

    const { $t } = getCurrentInstance().proxy;
    let pageObj = mixins(['getAssetswalletinfoList'],{walletType:5})
    const {page,listLoading,sizeChange,currentChange,getList} = pageObj

    const object_ = ref(object);
    const tableOption1 = ref({}); tableOption1.value = tableOption
    
    const pageStyle = ref(0)
    const textArray = ['',$t('currencySetNft.text1'),$t('currencySetNft.text2'),$t('currencySetNft.text3'),$t('currencySetNft.text4'),'查看NFT流水']
    const innerVisible1 = ref(false)
    const addView1 = ref(null)
    const updateView1 = ref(null)

    const completeFun = ()=>{
        if(pageStyle.value === 3){
            addView1.value.completeFun()
        }else{
            console.log(updateView1.value)
            updateView1.value.completeFun()
        }
    }
    const changeStatus = (value_) =>{
        let obj_ = {
            walletInfoId: value_.walletInfoId,
            status: value_.status
        }
        if(value_.walletId){
            Api_.updateAssetswalletinfoStatusView(obj_).then(res=>{
                if(res.code ===0 ){
                    getList(1)
                    ElNotification({
                        message: value_.status === 1?$t('alert.succOpen'):$t('alert.succOff'),
                        type: 'success',
                    })
                }else{
                    ElNotification({
                        message: res.msg,
                        type: 'error',
                    })
                }
            })
        }
    }
    const openView = (e,parms) =>{
        pageStyle.value = e
        innerVisible1.value = true
        if(e === 4){
            nextTick(()=>{
                updateView1.value.init_(parms)
            })
        }else if(e === 3){
            nextTick(()=>{
                addView1.value.init_()
            })
        }
    }
    const successFun = ()=>{
        innerVisible1.value = false
        getList(1)
    }
    getList(1)
</script>
<style lang="scss" scoped>
</style>