import il8n from '@/assets/language/index.js';
const { t } = il8n.global;
let dicFormatter1 = (e)=>{
    return e.records
}
export const tableOption = {
    border: true,
    height: 'auto',
    menuAlign: 'center',
    align: 'center',
    refreshBtn: false,
    showClomnuBtn: false,
    searchMenuSpan: 6,
    searchLabelWidth: '130',
    labelWidth: '150',
    columnBtn: false,
    addBtn: false,
    editBtn: false,
    delBtn: false,
    menu: false,
    column: [{
        width:100,
        label:  t('currencySetNft.name1'),
        prop: 'tenantId',
        type: 'select',
        dicUrl: '/admin/tenant/page',
        props:{
            value:'id',
            label:'name'
        },
        dicFormatter: dicFormatter1
    },{
        width:100,
        label: t('currencySetNft.name2'),
        prop: 'appName'
    },{
        width:80,
        label: t('currencySetNft.text6'),
        prop: 'chain'
    },{
        width:140,
        label: t('currencySetNft.name10'),
        prop: 'coin'
    },{
        width:120,
        label: t('otherWallatNft.name1'),
        prop: 'walletName',
    },{
        width:200,
        label: t('otherWallatNft.text6'),
        prop: 'walletAddress'
    },{
        width:100,
        label: t('nfts.name19'),
        prop: 'amount'
    },{
        width:100,
        label: t('currencySetNft.name5'),
        prop: 'priority'
    },{
        width:170,
        label: t('currencySetNft.name6'),
        prop: 'createTime'
    },{
        width:170,
        label: t('currencySetNft.name7'),
        prop: 'updateTime'
    },{
        width:120,
        label: t('currencySetNft.name8'),
        prop: 'updateUser'
    },{
        width:100,
        label: t('currencySetNft.name9'),
        prop: 'status',
        fixed:'right',
    },{
        width: 140,
        label: t("button.operation"),
        prop: 'caozuo',
        placeholder: t("inputTit.shuru"),
        fixed:'right',
    }]
}
export const addr = {
    border: true,
    height: '50vh',
    menuAlign: 'center',
    align: 'center',
    refreshBtn: false,
    showClomnuBtn: false,
    searchMenuSpan: 6,
    searchLabelWidth: '130',
    labelWidth: '150',
    columnBtn: false,
    addBtn: false,
    editBtn: false,
    delBtn: false,
    menu:false,
    column: [{
        width:80,
        label: t('currencySetNft.text6'),
        prop: 'chain'
    },{
        width:100,
        label: t('currencySetNft.text7'),
        prop: 'protocol'
    },{
        width:120,
        label: t('otherWallatNft.name1'),
        prop: 'walletName',
    },{
        label: t('otherWallatNft.text6'),
        prop: 'walletAddress'
    },{
        width:100,
        label: t('currencySetNft.name9'),
        prop: 'status',
        type: 'select',
        dicData: [{ label: t('currencySetNft.name11_1'), value: 1 },{label: t('currencySetNft.name11_2'),value:0}],
    },{
        width:100,
        label: t('currencySetNft.text8'),
        prop: 'selection',
    },]
}
export const coin1 = {
    border: true,
    menuAlign: 'center',
    align: 'center',
    refreshBtn: false,
    showClomnuBtn: false,
    searchMenuSpan: 6,
    searchLabelWidth: '130',
    labelWidth: '150',
    columnBtn: false,
    addBtn: false,
    editBtn: false,
    delBtn: false,
    menu:false,
    column: [{
        width: 100,
        label: t('currencySetNft.text6'),
        prop: 'chain',
        fixed:'left',
        type: 'select',
        span:24,
        editDisabled:true,
    }, {
        width:100,
        label: '币',
        prop: 'coin'
    }, 
    {
        label: t('agreement.text5'),
        prop: 'createTime',
        type: 'datetime',
        format: 'YYYY-MM-DD HH:mm:ss',
        valueFormat: 'YYYY-MM-DD HH:mm:ss',
        addDisplay: false,
        editDisplay: false,
        // search: true,
        searchRange: true
    },{
        label: t('currencySetNft.text8'),
        prop: 'selection'
    },]
}

  